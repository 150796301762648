import React from 'react';

export type SidebarProps = {
  title?: string | null;
  item?: readonly (string | null)[] | null;
};
const Sidebar: React.FunctionComponent<SidebarProps> = ({ title, item }) => {
  return (
    <aside>
      <h3>{title && <span>{title}</span>}</h3>
      <ul>
        {item?.map(
          (itemSideBar: string | null, index: number) =>
            itemSideBar && (
              <li key={`sidebar-item-${index}`}>
                <span>{itemSideBar}</span>
              </li>
            ),
        )}
      </ul>
    </aside>
  );
};

export default React.memo(Sidebar);
