import React from 'react';

import RichText from 'components/RichText';
import Sidebar, { SidebarProps } from './Sidebar';

import './styles.scss';

export type Intro = {
  title: string | null;
  description: {
    raw: string | null;
  } | null;
};

type IntroProps = {
  intro?: Intro | null;
  sidebar?: SidebarProps | null;
};

const IntroSidebar: React.FunctionComponent<IntroProps> = ({
  intro,
  sidebar,
}) => {
  return (
    <section className="intro-sidebar">
      <div className="container">
        {intro && intro.title && <h2>{intro.title}</h2>}
      </div>
      <div className="container">
        <div>
          {intro && intro.description?.raw && (
            <RichText applyStyle={true} content={intro.description.raw} />
          )}
        </div>
        <Sidebar {...sidebar} />
      </div>
    </section>
  );
};

export default React.memo(IntroSidebar);
