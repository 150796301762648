import React, { useRef } from 'react';

import FormSectionService from 'components/FormSection/Service';
import Main from 'components/Main';
import Service from 'components/Service';

type TemplateProps = Queries.ServiceQuery;

const Template: React.FunctionComponent<TemplateProps> = ({
  contentfulService: props,
}) => {
  const contactFormRef = useRef<HTMLElement | null>(null);

  return (
    <Main>
      <Service {...props} contactFormRef={contactFormRef} />
      <FormSectionService
        formRef={contactFormRef}
        slug={props?.slug}
        {...props?.formSection}
      />
    </Main>
  );
};

export default React.memo(Template);
