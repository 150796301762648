import React from 'react';

import { CopyProps } from '../types';
import RichText from 'components/RichText';

import './styles.scss';

const Copy: React.FunctionComponent<CopyProps> = ({ copy }) => {
  return (
    <section className="flex-copy">
      <div className="container">
        {copy && copy.raw && <RichText applyStyle={true} content={copy.raw} />}
      </div>
    </section>
  );
};

export default React.memo(Copy);
