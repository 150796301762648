import React from 'react';

import { Error, FormOrigin, Submitted, useForm } from 'modules/form';
import Form, { Model } from './Form';

import './styles.scss';

type ServiceFormType = {
  description?: {
    description: string | null;
  } | null;
  formRef: React.MutableRefObject<HTMLElement | null>;
  slug?: string | null;
  submittedDescription?: {
    raw: string | null;
  } | null;
  submittedTitle?: string | null;
  title?: string | null;
};

const ServiceForm: React.FunctionComponent<ServiceFormType> = ({
  description,
  formRef,
  submittedDescription,
  submittedTitle,
  slug,
  title,
}) => {
  const {
    errorRef,
    handleSubmit,
    isError,
    isSubmitted,
    isSubmitting,
    recaptcha,
    setIsSubmitting,
    submittedRef,
  } = useForm<Model>({ formOrigin: `service-${slug}` as FormOrigin });

  return isError ? (
    <Error
      className="service-settled"
      forwardedRef={errorRef}
      message={isError}
    />
  ) : isSubmitted ? (
    <Submitted
      className="service-settled"
      description={submittedDescription?.raw}
      forwardedRef={submittedRef}
      title={submittedTitle}
    />
  ) : (
    <Form
      description={description?.description}
      formRef={formRef}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      recaptcha={recaptcha}
      setIsSubmitting={setIsSubmitting}
      title={title}
    />
  );
};

export default React.memo(ServiceForm);
