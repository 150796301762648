import React from 'react';

import { richTextListItem } from '../../types';
import RichText from 'components/RichText';

const GridItem: React.FunctionComponent<richTextListItem> = ({
  title,
  description,
}) => {
  return (
    <li>
      <h3>{title}</h3>
      {description && description.raw && (
        <RichText applyStyle={true} content={description?.raw} />
      )}
    </li>
  );
};

export default React.memo(GridItem);
