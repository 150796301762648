import React from 'react';

import { LinkSize, LinkColour } from 'components/Hyperlink';
import { ListProps, richTextListItem } from '../types';
import { useScrollTo } from 'components/Service/useScrollTo';
import ListItem from './components/ListItem';
import PageLink from 'components/Hyperlink/PageLink';
import RichText from 'components/RichText';

import './styles.scss';

const List: React.FunctionComponent<ListProps> = ({
  contactFormRef,
  description,
  title,
  richTextList,
}) => {
  const { handleOnClickScrollTo } = useScrollTo(contactFormRef);
  return (
    <section className="flex-boxes">
      <div className="container">
        <h2>{title}</h2>
        <div>
          <PageLink
            colour={LinkColour.RED}
            label="Get in touch"
            onClick={handleOnClickScrollTo}
            size={LinkSize.SMALL}
            slug="#get-in-touch"
          />
        </div>
        {description && description.raw && (
          <RichText applyStyle={true} content={description?.raw} />
        )}
        <ul>
          {richTextList?.map((item: richTextListItem | null, index: number) => (
            <ListItem key={`flex-list-item-${index}`} {...item} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default React.memo(List);
