import React from 'react';
import clsx from 'clsx';

import { FeaturedCaseStudyProps } from '../types';
import { LazyloadBackground } from 'components/Image';
import PageLink from 'components/Hyperlink/PageLink';
import RichText from 'components/RichText';
import Slice from 'modules/theme/images/form/slice.svg';

import './styles.scss';

const FeaturedCaseStudy: React.FunctionComponent<FeaturedCaseStudyProps> = ({
  caseStudyCtaReference: caseStudy,
  title,
}) => {
  const caseStudyGenerics = caseStudy?.caseStudyGenerics;

  const className = clsx(
    'flex-cs',
    !caseStudyGenerics?.quoteReference && 'no-copy',
  );
  return (
    <section className={className}>
      <div className="container">
        <h2>{title}</h2>
        <div>
          <div>
            <span>{caseStudyGenerics?.clientName}</span>
            <h3>{caseStudyGenerics?.title}</h3>
            {caseStudyGenerics?.slug && (
              <PageLink slug={caseStudyGenerics?.slug}>Read more</PageLink>
            )}
            {caseStudyGenerics?.quoteReference?.quote.raw && (
              <div className="text-container">
                <figure>
                  <blockquote>
                    <RichText
                      applyStyle={true}
                      content={caseStudyGenerics?.quoteReference?.quote.raw}
                    />
                  </blockquote>
                  <figcaption>
                    {caseStudyGenerics?.quoteReference?.authorName?.raw && (
                      <RichText
                        applyStyle={true}
                        content={
                          caseStudyGenerics?.quoteReference?.authorName.raw
                        }
                      />
                    )}
                  </figcaption>
                </figure>
                <div className="author-location">
                  {caseStudyGenerics?.quoteReference?.authorLocation?.raw && (
                    <RichText
                      applyStyle={true}
                      content={
                        caseStudyGenerics?.quoteReference?.authorLocation?.raw
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <Slice />
          {caseStudyGenerics?.smallImage && (
            <LazyloadBackground
              image={caseStudyGenerics.smallImage.image}
              local={false}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default React.memo(FeaturedCaseStudy);
