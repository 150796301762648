import React from 'react';

import { LazyloadImg } from 'components/Image';
import { ImageProps } from '../types';

import './styles.scss';

const Image: React.FunctionComponent<ImageProps> = ({ title, image }) => {
  return (
    <section className="flex-image">
      <div className="container">
        <h3>{title}</h3>
        <div>
          {image && (
            <LazyloadImg
              alt={image.alt || ''}
              image={image.image}
              local={false}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default React.memo(Image);
