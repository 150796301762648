import React from 'react';

import { LinkSize, LinkColour } from 'components/Hyperlink';
import { useScrollTo } from '../useScrollTo';
import PageLink from 'components/Hyperlink/PageLink';

import './styles.scss';

type HeroServiceProps = {
  contactFormRef: React.MutableRefObject<HTMLElement | null>;
  heroTitle?: {
    heroTitle: string | null;
  } | null;
  title?: string | null;
};

const ServiceHero: React.FunctionComponent<HeroServiceProps> = ({
  contactFormRef,
  heroTitle,
  title,
}) => {
  const { handleOnClickScrollTo } = useScrollTo(contactFormRef);
  return (
    <section className="hero">
      <div className="container">
        <h1>{heroTitle?.heroTitle ?? title}</h1>
        <PageLink
          colour={LinkColour.DARK_BLUE}
          label="Get in touch"
          onClick={handleOnClickScrollTo}
          size={LinkSize.SMALL}
          slug="#get-in-touch"
        />
      </div>
    </section>
  );
};

export default React.memo(ServiceHero);
