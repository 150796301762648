import { useCallback } from 'react';

export const useScrollTo = (
  contactFormRef: React.MutableRefObject<HTMLElement | null>,
) => {
  const handleOnClickScrollTo = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      contactFormRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    [contactFormRef],
  );

  return {
    handleOnClickScrollTo,
  };
};
