import React from 'react';

import { richTextListItem } from '../../types';
import RichText from 'components/RichText';

import './styles.scss';

const ListItem: React.FunctionComponent<richTextListItem> = ({
  description,
}) => (
  <li className="flex-service-list-item">
    {description && description.raw && (
      <RichText applyStyle={true} content={description?.raw} />
    )}
  </li>
);

export default React.memo(ListItem);
