import React from 'react';

import {
  FEATURED_CASE_STUDY_LIST,
  COPY_LIST,
  FLEXIBLE_GRID,
  FLEXIBLE_IMAGE,
  FLEXIBLE_LIST,
} from './constants';
import Grid from './Grid';
import List from './List';
import Image from './Image';
import {
  CopyProps,
  FeaturedCaseStudyProps,
  GridProps,
  ImageProps,
  ListProps,
} from './types';
import FeaturedCaseStudy from './FeaturedCaseStudy';
import Copy from './Copy';

export type FlexibleItem =
  | ListProps
  | GridProps
  | ImageProps
  | FeaturedCaseStudyProps
  | CopyProps
  | null;

type FlexibleProps = {
  contactFormRef: React.MutableRefObject<HTMLElement | null>;
  items: readonly FlexibleItem[];
};

const Flexible: React.FunctionComponent<FlexibleProps> = ({
  contactFormRef,
  items,
}) => {
  return (
    <div className="flexible">
      {items.map((item: FlexibleItem, index: number) => {
        switch (item?.internal?.type) {
          case FLEXIBLE_LIST:
            return (
              <List
                contactFormRef={contactFormRef}
                key={`flex-item-${index}`}
                {...item}
              />
            );
          case FLEXIBLE_GRID:
            return (
              <Grid
                contactFormRef={contactFormRef}
                key={`flex-item-${index}`}
                {...item}
              />
            );
          case FLEXIBLE_IMAGE:
            return <Image key={`flex-item-${index}`} {...item} />;
          case FEATURED_CASE_STUDY_LIST:
            return <FeaturedCaseStudy key={`flex-item-${index}`} {...item} />;
          case COPY_LIST:
            return <Copy key={`flex-item-${index}`} {...item} />;
          default:
            return null;
        }
      })}
    </div>
  );
};

export default React.memo(Flexible);
