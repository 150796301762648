import React from 'react';

import { GridProps, richTextListItem } from '../types';
import { LinkSize, LinkColour } from 'components/Hyperlink';
import { useScrollTo } from 'components/Service/useScrollTo';
import GridItem from './components/GridItem';
import PageLink from 'components/Hyperlink/PageLink';

import './styles.scss';

const Grid: React.FunctionComponent<GridProps> = ({
  contactFormRef,
  title,
  richTextList,
}) => {
  const { handleOnClickScrollTo } = useScrollTo(contactFormRef);

  return (
    <section className="flex-grid">
      <div className="container">
        <h2>{title}</h2>
        <div>
          <PageLink
            colour={LinkColour.RED}
            label="Get in touch"
            onClick={handleOnClickScrollTo}
            size={LinkSize.SMALL}
            slug="#get-in-touch"
          />
        </div>
        <ul>
          {richTextList?.map((item: richTextListItem | null, index: number) => (
            <GridItem key={`flex-grid-item-${index}`} {...item} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default React.memo(Grid);
