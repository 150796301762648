import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout, { ILayout } from 'modules/layout';
import Meta from 'modules/meta';
import Template from './Template';

export const query = graphql`
  query Service($id: String) {
    contentfulSiteConfig {
      ...siteConfig
    }
    contentfulService(id: { eq: $id }) {
      id
      slug
      title
      ...formSectionService
      ...metaService
      ...heroTitleService
      ...introReferenceService
      ...sidebarReferenceService
      ...flexibleContentService
    }
  }
`;

const Page: React.FunctionComponent<PageProps<Queries.ServiceQuery>> &
  ILayout = ({ data }) => {
  return <Template {...data} />;
};

Page.Layout = Layout;
export default Page;

export const Head: React.FunctionComponent<PageProps<Queries.ServiceQuery>> = ({
  data,
  location,
}) => {
  return (
    <Meta
      defaultMeta={data.contentfulSiteConfig?.defaultMeta}
      meta={data.contentfulService?.meta}
      pageTitle={data.contentfulService?.title}
      pathname={location.pathname}
    />
  );
};
