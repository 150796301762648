import React from 'react';

import { SidebarProps } from './IntroSidebar/Sidebar';
import Flexible, { FlexibleItem } from './FlexibleSection';
import IntroSidebar, { Intro } from './IntroSidebar';
import ServiceHero from './Hero';

type ServiceProps = {
  contactFormRef: React.MutableRefObject<HTMLElement | null>;
  flexContent?: readonly FlexibleItem[] | null;
  heroTitle?: {
    heroTitle: string | null;
  } | null;
  intro?: Intro | null;
  sidebar?: SidebarProps | null;
  title?: string | null;
};
const Service: React.FunctionComponent<ServiceProps> = ({
  contactFormRef,
  flexContent,
  heroTitle,
  intro,
  sidebar,
  title,
}) => {
  return (
    <div className="service">
      <ServiceHero
        contactFormRef={contactFormRef}
        heroTitle={heroTitle}
        title={title}
      />
      <IntroSidebar intro={intro} sidebar={sidebar} />
      {flexContent && flexContent.length > 0 && (
        <Flexible contactFormRef={contactFormRef} items={flexContent} />
      )}
    </div>
  );
};

export default React.memo(Service);
